export const environment = {
  production: true,
  apiBaseUrl: 'https://b101p01as-backend01.azurewebsites.net',
  clientId: 'f4362ad9-069b-4dfb-9dfc-d19eae3e73e1',
  authority: 'https://login.microsoftonline.com/fad277c9-c60a-4da1-b5f3-b3b8b34a82f9',
  redirectUri: 'https://funding-data.education.gov.uk/.auth/login/aad/callback',
  scope: ['https://educationgovuk.onmicrosoft.com/fundingdataserviceapi/user_impersonation'],
  sysAdminGroup: 'AZURE-FDS-SYSADMIN-USERS',
  useLocalHttpInterceptor: false,
  localToken: '',
  environment: 'prod',
};
